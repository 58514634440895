import React, { useState } from 'react';
import '../assets/styles.css';

function ContactForm() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic
     // Construct the mailto link
    const mailtoLink = `mailto:hello.tokassets@gmail.com?subject=${encodeURIComponent(formData.subject)}&body=${encodeURIComponent(`Name: ${formData.name}\nEmail: ${formData.email}\n\nMessage: ${formData.message}`)}`;

    // Open the default email client with pre-filled information
    window.location.href = mailtoLink;
  };

  return (
    <section className="contact-form">
      <div className="container">
        <h2>Contact us</h2>
        <form onSubmit={handleSubmit}>
          <input 
            type="text" 
            name="name" 
            placeholder="Name" 
            onChange={handleChange} 
            value={formData.name} 
            required 
          />
          <input 
            type="email" 
            name="email" 
            placeholder="Email" 
            onChange={handleChange} 
            value={formData.email} 
            required 
          />
          <input 
            type="text" 
            name="subject" 
            placeholder="Subject" 
            onChange={handleChange} 
            value={formData.subject} 
            required 
          />
          <textarea 
            name="message" 
            placeholder="Message" 
            onChange={handleChange} 
            value={formData.message} 
            required 
          ></textarea>
          <button type="submit">Send Message</button>
        </form>
      </div>
    </section>
  );
}

export default ContactForm;
