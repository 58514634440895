import React from 'react';
import HeroSection from '../components/HeroSection';
import Benefits from '../components/Benefits';
import Features from '../components/Features';
import Roadmap from '../components/Roadmap'; // Import Roadmap
import Investments from '../components/Investments';

function Home() {
  return (
    <>
      <HeroSection />
      <Benefits />
      <Investments />
      <Features />
      <Roadmap /> 
    </>
  );
}

export default Home;

