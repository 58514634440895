import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../../assets/styles.css';

function DisplayShares() {
  const navigate = useNavigate();

  // Function to go back to the previous page
  const goBack = () => {
    navigate(-1); // Go back to the page where the user came from
  };

  return (
    <section className="investment-details">
      <div className="container">
        
        {/* Back Link Row */}
        <div className="back-link">
          <button onClick={goBack}>&larr; Back</button>
        </div>
        
        <div className="investment-content">
          {/* Left-Aligned Image Section */}
            <div className="investment-image">
              <img src="/Shares.jpg" alt="Shares Illustration" />
            </div>

          {/* Investment Details */}
          <div className="investment-text">
            <h2>Shares</h2>
            <p>
              Shares represent ownership in a company, giving investors a stake in the company’s equity. Traditionally, shares are traded on stock exchanges, but tokenized shares bring a new level of flexibility and accessibility.
            </p>
            <h3>How It Works</h3>
            <p>
              Tokenized shares are digital representations of traditional equity shares that are issued on a blockchain. These tokens function like regular shares, providing ownership rights, dividends, and voting power in the company. However, the major advantage is that they can be traded instantly on our platform without the need for traditional intermediaries.
            </p>
            <p>
              Tokenization simplifies the process, reduces fees, and allows for fractional ownership, meaning investors can own smaller portions of a company if they wish. This opens the door to broader participation in the stock market.
            </p>
            <p><strong>Potential IRR:</strong> 8-12%</p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default DisplayShares;