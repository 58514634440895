import React from 'react';
import '../assets/styles.css';
import DisplayRealEstate from '../components/ComponentsAssetsDetails/DisplayRealEstate';

function RealEstate() {
  return (
    <div>
      <DisplayRealEstate />
    </div>
  );
}

export default RealEstate;
