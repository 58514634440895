import React from 'react';
import '../assets/styles.css';

function Team() {
  return (
    <section className="team">
      <div className="container">
        <h2>Meet us</h2>

        {/* TokAssets Section: Why, Vision, Values */}
        <div className="tokassets-section">
          <h3>Why TokAssets?</h3>
          <p>
            At TokAssets, our mission is to reshape the future of asset management through the power of blockchain. We believe that tokenization is the key to unlocking new value in traditional assets and making investments more accessible and transparent for everyone.
          </p>
          
          <h3>Our Vision</h3>
          <p>
            To create a world where assets of all types—from real estate to commodities—are seamlessly tokenized and made accessible to investors globally through our secure platform.
          </p>
          
          <h3>Our Values</h3>
          <ul>
            <li><strong>Innovation:</strong> We are committed to staying at the forefront of blockchain technology and bringing groundbreaking solutions to the asset management industry.</li>
            <li><strong>Transparency:</strong> We believe in openness and clarity, ensuring that every transaction on our platform is traceable and auditable.</li>
            <li><strong>Inclusivity:</strong> Our goal is to democratize access to wealth-building opportunities by making high-value assets available to a broader audience.</li>
            <li><strong>Security:</strong> We prioritize the security of our users' assets, using cutting-edge technology to protect investments and data.</li>
          </ul>
        </div>
        
        {/* CEO Section */}
        <div className="team-member">
          <h3>Mathieu Dupré - Founder & CEO</h3>
          <img src="../../MD.jpg" alt="Mathieu Dupré - CEO" className="team-member-image" />
          <p>
            Mathieu Dupré is an innovative and dynamic leader 
            with a strong background in business law and extensive 
            experience in wealth management, mergers and acquisitions, 
            and private equity. After transitioning into the tech industry 
            and completing program at the Blockchain Business School, 
            he became a certified blockchain Tech Lead with expertise 
            in smart contract development and Web3 technologies.
          </p>
          <p>
            With over a decade of experience managing wealth 
            management operations and driving innovation and digital 
            transformation projects, Mathieu has worked for leading 
            law firms and companies. His expertise spans business law,
            wealth management engineering, innovation management, 
            blockchain technology, and entrepreneurship, 
            making him a versatile and forward-thinking CEO for TokAssets.
          </p>
          <p>
            He holds a Master’s degree in Wealth Management Engineering and a Master's degree in Entrepreneurship & Innovation 
            Management from emlyon business school and holds advanced 
            certification in Corporate Finance from HEC.
          </p>
          <p>
            His vision is to revolutionize the asset management industry 
            through tokenization and blockchain-driven solutions.
          </p>
          <p>
            <strong>Key Competencies:</strong> Business Law, Wealth Management Engineering, Product Management, Blockchain 
            technology, smart contracts, full-stack development, 
            Innovation Management, and Digital Transformation.
          </p>
          <p>
            <a href="https://www.linkedin.com/in/mathieu-dupré" target="_blank" rel="noopener noreferrer">
              Connect with Mathieu on LinkedIn
            </a>
          </p>
        </div>
        
        {/* Add more team members as needed */}
      </div>
    </section>
  );
}

export default Team;
