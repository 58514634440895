import React from 'react';
import '../assets/styles.css';
import DisplayFineArt from '../components/ComponentsAssetsDetails/DisplayFineArt';

function FineArt() {
  return (
    <div>
      <DisplayFineArt />
    </div>
  );
}

export default FineArt;
