import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import FeaturesPage from './pages/FeaturesPage';
import AboutUs from './pages/AboutUs';
import Contact from './pages/Contact';
import WhitePaper from './pages/WhitePaper';
import Presale from './pages/Presale';
import Navbar from './components/Navbar';
import Collectibles from './pages/Collectibles';
import Shares from './pages/Shares';
import PrivateEquity from './pages/PrivateEquity';
import Diamonds from './pages/Diamonds';
import FineArt from './pages/FineArt';
import Bonds from './pages/Bonds';
import Gold from './pages/Gold';
import RealEstate from './pages/RealEstate';
import ScrollToTop from './ScrollToTop';

function AppRoutes() {
  return (
    <>
      <Navbar />
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/features" element={<FeaturesPage />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/whitepaper" element={<WhitePaper />} />
        <Route path="/presale" element={<Presale />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/pages/collectibles" element={<Collectibles />} />
        <Route path="/pages/shares" element={<Shares />} />
        <Route path="/pages/private-equity" element={<PrivateEquity />} />
        <Route path="/pages/diamonds" element={<Diamonds />} />
        <Route path="/pages/fine-art" element={<FineArt />} />
        <Route path="/pages/bonds" element={<Bonds />} />
        <Route path="/pages/gold" element={<Gold />} />
        <Route path="/pages/real-estate" element={<RealEstate />} />
      </Routes>
    </>
  );
}

export default AppRoutes;
