import React from 'react';
import '../assets/styles.css';
import WP from '../components/WP';

function WhitePaper() {
  return (
    <WP />
  );
}

export default WhitePaper;