import React from 'react';
import '../assets/styles.css';

function Features() {
  return (
    <section className="features">
      <div className="container">
        <h2>Our Features</h2>
        <div className="features-content">
          <ul>
            <li>Seamless Platform</li>
            <li>Access to new investments</li>
            <li>Tokenization Services</li>
            <li>Fractional Ownership of Real-World Assets</li>
            <li>Automated Regulatory Compliance and Register Management</li>
            <li>Instant Settlement and Near-Instant Transactions</li>
            <li>Low Entry Barriers for Investors</li>
            <li>Transparency Through Blockchain</li>
            <li>Advanced Security and Interoperability</li>
            <li>Smart Contracts for Automated Legal and Financial Processes</li>
            <li>Marketplace for Secondary Trading</li>
            <li>Native Token (TKA) Utilization for buying and trading</li>
            <li>Multi-Chain Asset Support</li>
            <li>Portfolio Management Tools</li>
          </ul>
          <div className="features-image">
            <img src="Madrid.jpg" alt="TokAssets Features" />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Features;
