import React from 'react';
import { useNavigate } from 'react-router-dom'; // for navigation
import '../../assets/styles.css';

function DisplayGold() {
  const navigate = useNavigate();

  // Function to go back to the previous page
  const goBack = () => {
    navigate(-1); // Go back to the page where the user came from
  };

  return (
    <section className="investment-details">
      <div className="container">
        
        {/* Back Link Row */}
        <div className="back-link">
          <button onClick={goBack}>&larr; Back</button>
        </div>

        <div className="investment-content">
          {/* Left-Aligned Image Section */}
          <div className="investment-image">
            <img src="/Gold.jpg" alt="Gold Illustration" />
          </div>

          {/* Investment Details */}
          <div className="investment-text">
            <h2>Gold</h2>
            <p>
              Gold has been used as a store of value for thousands of years. It is one of the most stable and trusted investment assets, known for its ability to hedge against inflation and economic downturns.
            </p>
            <h3>How It Works</h3>
            <p>
              Tokenized gold allows investors to own fractions of gold bars, which are securely stored by custodians. Each token represents a specific quantity of physical gold, and the tokens can be traded on our platform. This offers greater liquidity and flexibility compared to owning physical gold, which can be costly to store and insure.
            </p>
            <p>
              By investing in tokenized gold, investors can benefit from price movements in the gold market while avoiding the logistical challenges of handling the physical asset.
            </p>
            <p><strong>Potential IRR:</strong> 5%</p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default DisplayGold;
