import React, { useState } from 'react';
import '../assets/styles.css';

function DisplayPresale() {

  const [showTokenUtility, setShowTokenUtility] = useState(false);
  const [showTokenAllocation, setShowTokenAllocation] = useState(false);

  return (
    <section className="presale">
      <div className="container">
        <h2>Presale</h2>
        {/* How to Buy TokAssets Section */}
        <div className="section purchase-guide">
          <h3>🛒 How to Buy TokAssets 🛒</h3>
          <p>Follow this guide to participate in the TokAssets presale and invest in the leading platform of tokenization by sending ETH and receiving an airdrop of TokAssets tokens.</p>
          
          {/* Toggle buttons for expanding sections */}
          {/* Token Allocation Breakdown Section */}
          <div className="toggle-section">
            <div className="toggle-button" onClick={() => setShowTokenAllocation(!showTokenAllocation)}>
              <p>Token Allocation Breakdown <span>{showTokenAllocation ? '-' : '+'}</span></p>
              
            </div>
            {showTokenAllocation && (
              <div id="token-allocation" className="section">
                <h3>Token Allocation Breakdown</h3>
                <ul className="list">
                  <li><strong>Pre-sale:</strong> 50% – Allocated for the presale to raise initial capital, to build a seamless world-class Tokenization Platform, and establish a strong community to whom we can offer the best investment opportunities.</li>
                  <li><strong>Team and Founders:</strong> 20% – With a 4-year vesting period (including a 1-year cliff), ensuring market stability and aligning the team's incentives with long-term project success.</li>
                  <li><strong>Reserve Fund:</strong> 5% – Set aside to cover unforeseen expenses, future development, strategic acquisitions, and other operational needs.</li>
                  <li><strong>Partnerships and Advisors:</strong> 10% – With a 4-year vesting period to ensure long-term commitment and alignment with strategic partners and advisors.</li>
                  <li><strong>Marketing and Community Building:</strong> 5% – Allocated to fund ongoing marketing campaigns, community engagement initiatives, and educational content to drive platform adoption and growth.</li>
                  <li><strong>Liquidity Provision:</strong> 5% – Reserved to provide liquidity on exchanges, ensuring smooth trading and reducing price volatility.</li>
                </ul>
              </div>
            )}

            {/* Utility of the Token Section */}
            <div className="toggle-button" onClick={() => setShowTokenUtility(!showTokenUtility)}>
              <p>Token Utility <span>{showTokenUtility ? '-' : '+'}</span></p>
              
            </div>
            {showTokenUtility && (
              <div id="token-utility" className="section">
                <h3>Utility of the Token (TKA)</h3>
                <ul className="list">
                  <li><strong>Transaction Fees:</strong> Used for all transactions on the platform, including trading, payments, and service fees. A portion of the transaction fees will be burned periodically, introducing a deflationary mechanism to decrease the total supply over time and potentially increase the token's value.</li>
                  <li><strong>Staking:</strong> TKA holders can stake their tokens to earn rewards and participate in governance voting. Staking incentives will be provided from a dedicated staking pool, and rewards will be distributed based on the duration and amount staked, fostering long-term holding and community engagement.</li>
                  <li><strong>Access to Premium Features:</strong> TKA holders will gain exclusive access to certain high-value investment opportunities, discounted fees, and premium services on the TokAssets platform, enhancing the value proposition for holding the token.</li>
                </ul>
              </div>
            )}
          </div>

          <h4>Step-by-Step Guide:</h4>
          <div className="guide-step">
            <div>
              <h5>1. Set Up Your Wallet</h5>
              <p>To participate, use a compatible cryptocurrency wallet such as MetaMask or Trust Wallet.</p>
              <ul className="list">
                <li>Download and install MetaMask or Trust Wallet.</li>
                <li>Create a new wallet or import an existing one.</li>
                <li>Store your seed phrase securely (DO NOT share it).</li>
                <li>Fund your wallet with ETH from an exchange or another wallet.</li>
              </ul>
            </div>
            
            <div>
              <h5>2. Send ETH to the Pre-Sale Wallet</h5>
              <div className="qr-code-container">
                <div className="qr-code-text">
                  <p><strong>TokAssets Wallet Address:</strong></p>
                  <p className="wallet-address">0xEfC050A6578E5adb98C555a0B591C34C2bBc602D</p>
                  <p> Or scan the QR code to copy the wallet address:</p>
                </div>
                <img src="/QRCodeTokassets.jpg" alt="QR Code for TokAssets Wallet Address" className="qr-image" />
              </div>
              <ul className="list">
                <li>Open your wallet and select "Send."</li>
                <li>Enter the pre-sale wallet address.</li>
                <li>Input the amount of ETH you wish to invest.</li>
                <li>Review your transaction details and gas fees.</li>
                <li>Click "Send" to complete the transaction.</li>
              </ul>
            </div>
            
            <div>
              <h5>3. Receive Airdrop of TokAssets Tokens</h5>
              <p>After the pre-sale ends, you will receive an airdrop of TokAssets tokens directly to your wallet.</p>
            </div>
          </div>
          
          <h4>Stay Updated</h4>
          <p>Check the Announcements channel on our social medias for updates on pre-sale progress and the airdrop schedule.</p>

          {/* Important Notes Section */}
          <h4>⚠️ Important Notes:</h4>
          <ul className="list">
            <li>Only send ETH and only sent to the official pre-sale wallet address listed here.</li>
            <li>Ensure you have enough ETH to cover the investment and gas fees.</li>
            <li>Double-check all details before sending funds.</li>
          </ul>
        </div>   
        
      </div>
    </section>
  );
}

export default DisplayPresale;
