import React from 'react';
import '../assets/styles.css';

function Roadmap() {
  return (
    <section className="roadmap">
      <div className="container">
        <h2>Our Roadmap</h2>

        <div className="timeline">
          <div className="timeline-item">
            <div className="timeline-date">2024 Q3</div>
            <div className="timeline-content">
              <h3>Project Initiation</h3>
              <p>Kick-off and project planning, development of a community on social media, site web launch.</p>
            </div>
          </div>
          <div className="timeline-item">
            <div className="timeline-date">2024 Q4</div>
            <div className="timeline-content">
              <h3>Deployment of Smart Contracts</h3>
              <p>Launch on Testnet, audit, and web platform development (including set up of a technical stack capable of sustaining millions orders per second, UI/UX, multi languages and on multi device coverage on Ios, Android, Windows, Mac...). Community development and partnerships</p>
            </div>
          </div>
          <div className="timeline-item">
            <div className="timeline-date">2025 Q1</div>
            <div className="timeline-content">
              <h3>Beta Testing & Regulatory Approval</h3>
              <p>Conduct testing and secure necessary approvals. Continue community growth and partnerships.</p>
            </div>
          </div>
          <div className="timeline-item">
            <div className="timeline-date">2025 Q4</div>
            <div className="timeline-content">
              <h3>CASP Approval</h3>
              <p>Obtain the necessary Crypto-Asset Service Provider (CASP) approval to be able to tokenized and sell this kind of assets.</p>
            </div>
          </div>
          <div className="timeline-item">
            <div className="timeline-date">2026 Q1</div>
            <div className="timeline-content">
              <h3>Platform Launch</h3>
              <p>Launch of the Plateform on multi devices and the first tokenized fund.</p>
            </div>
          </div>
          <div className="timeline-item">
            <div className="timeline-date">2026 Q2</div>
            <div className="timeline-content">
              <h3>Expansion to Other Assets</h3>
              <p>Expand to new assets like private equity funds shares, gold, diamonds, arts, real estate and clients acquisition.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Roadmap;
