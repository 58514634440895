import React from 'react';
import { useNavigate } from 'react-router-dom'; // for navigation
import '../../assets/styles.css';

function DisplayDiamonds() {
  const navigate = useNavigate();

  // Function to go back to the previous page
  const goBack = () => {
    navigate(-1); // Go back to the page where the user came from
  };

  return (
    <section className="investment-details">
      <div className="container">
        
        {/* Back Link Row */}
        <div className="back-link">
          <button onClick={goBack}>&larr; Back</button>
        </div>

        <div className="investment-content">
          {/* Left-Aligned Image Section */}
          <div className="investment-image">
            <img src="/Diamonds.jpg" alt="Diamonds Illustration" />
          </div>

          {/* Investment Details */}
          <div className="investment-text">
            <h2>Diamonds</h2>
            <p>
              Diamonds are one of the most sought-after precious stones, valued for their rarity, beauty, and durability. Investing in diamonds has traditionally been the domain of the wealthy, but tokenization is making this asset class more accessible.
            </p>
            <h3>How It Works</h3>
            <p>
              Tokenized diamonds allow investors to buy fractional ownership of high-value diamonds. Each token represents a share in a diamond or a portfolio of diamonds, and these tokens can be traded on our platform, making the asset more liquid.
            </p>
            <p>
              With tokenization, investors can diversify their portfolios by gaining exposure to diamonds without the need for physically handling or storing the gems. As diamond prices increase, so does the value of the tokens representing them.
            </p>
            <p><strong>Potential IRR:</strong> 6%</p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default DisplayDiamonds;
