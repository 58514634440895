import React from 'react';
import '../assets/styles.css';
import DisplayCollectibles from '../components/ComponentsAssetsDetails/DisplayCollectibles';

function Collectibles() {
  return (
    <div>
      <DisplayCollectibles />
    </div>
  );
}


export default Collectibles;
