import React from 'react';
import { useNavigate } from 'react-router-dom'; // for navigation
import '../../assets/styles.css';

function DisplayRealEstate() {
  const navigate = useNavigate();

  // Function to go back to the previous page
  const goBack = () => {
    navigate(-1); // Go back to the page where the user came from
  };

  return (
    <section className="investment-details">
      <div className="container">
        
        {/* Back Link Row */}
        <div className="back-link">
          <button onClick={goBack}>&larr; Back</button>
        </div>

        <div className="investment-content">
          {/* Left-Aligned Image Section */}
          <div className="investment-image">
            <img src="/RealEstate.jpg" alt="Real Estate Illustration" />
          </div>

          {/* Investment Details */}
          <div className="investment-text">
            <h2>Real Estate</h2>
            <p>
              Real estate is a tangible asset class that includes residential, commercial, and industrial properties. It is a popular investment due to its potential for appreciation and income generation through rental yields.
            </p>
            <h3>How It Works</h3>
            <p>
              Tokenized real estate enables investors to buy fractional ownership of properties. Each token represents a share in a real estate asset, and investors can trade these tokens on our platform, providing liquidity to an otherwise illiquid asset class.
            </p>
            <p>
              Tokenization opens up the real estate market to a wider range of investors, allowing people to invest in high-value properties without needing to purchase entire buildings. As the property value increases or rental income is generated, token holders can benefit.
            </p>
            <p><strong>Potential IRR:</strong> 8%</p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default DisplayRealEstate;
