import React from 'react';
import AppRoutes from './routes';
import Footer from './components/Footer';
import './assets/styles.css';  // Global CSS styles

function App() {
  return (
    <>
      <AppRoutes />
      <Footer />
    </>
  );
}

export default App;
