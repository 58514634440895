import React from 'react';
import '../assets/styles.css';
import DisplayGold from '../components/ComponentsAssetsDetails/DisplayGold';

function Gold() {
  return (
    <div>
      <DisplayGold />
    </div>
  );
}

export default Gold;