import React from 'react';
import { useNavigate } from 'react-router-dom'; // for navigation
import '../../assets/styles.css';

function DisplayFineArt() {
  const navigate = useNavigate();

  // Function to go back to the previous page
  const goBack = () => {
    navigate(-1); // Go back to the page where the user came from
  };

  return (
    <section className="investment-details">
      <div className="container">
        
        {/* Back Link Row */}
        <div className="back-link">
          <button onClick={goBack}>&larr; Back</button>
        </div>

        <div className="investment-content">
          {/* Left-Aligned Image Section */}
          <div className="investment-image">
            <img src="/FineArt.jpg" alt="Fine Art Illustration" />
          </div>

          {/* Investment Details */}
          <div className="investment-text">
            <h2>Fine Art</h2>
            <p>
              Fine art includes paintings, sculptures, and other creative works that are highly valuable due to their artistic and cultural significance. Historically, investing in fine art has been limited to wealthy collectors and institutions, but tokenization is changing that.
            </p>
            <h3>How It Works</h3>
            <p>
              Tokenizing fine art allows investors to purchase a fraction of a valuable piece of artwork. Each token represents partial ownership of the artwork, and the tokens can be traded on our platform. This makes investing in expensive masterpieces accessible to a broader audience.
            </p>
            <p>
              The underlying artwork is stored in secure, climate-controlled facilities, and as the value of the art increases, so does the value of the associated tokens. Investors benefit from price appreciation without needing to buy or store physical art.
            </p>
            <p><strong>Potential IRR:</strong> 7%</p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default DisplayFineArt;
