import React from 'react';
import { useNavigate } from 'react-router-dom'; // for navigation
import '../../assets/styles.css';

function DisplayBonds() {
  const navigate = useNavigate();

  // Function to go back to the previous page
  const goBack = () => {
    navigate(-1); // Go back to the page where the user came from
  };

  return (
    <section className="investment-details">
      <div className="container">
        
        {/* Back Link Row */}
        <div className="back-link">
          <button onClick={goBack}>&larr; Back</button>
        </div>

        <div className="investment-content">
          {/* Left-Aligned Image Section */}
          <div className="investment-image">
            <img src="/Bonds.jpg" alt="Bonds Illustration" />
          </div>

          {/* Investment Details */}
          <div className="investment-text">
            <h2>Bonds</h2>
            <p>
              Bonds are fixed-income securities issued by governments, municipalities, or corporations to raise capital. Investors lend money to the issuer in exchange for periodic interest payments and the return of the bond's face value at maturity.
            </p>
            <h3>How It Works</h3>
            <p>
              Tokenized bonds function similarly to traditional bonds but offer greater flexibility and liquidity. Investors can buy fractional amounts of bonds using blockchain tokens, which can be easily traded on our digital platform. This reduces barriers to entry for smaller investors who want to participate in bond markets without committing large sums of money.
            </p>
            <p>
              Tokenized bonds provide the same benefits as traditional bonds, such as regular interest payments (coupon payments) and lower risk compared to equities. The tokens are backed by the underlying bond assets, ensuring the security of the investment.
            </p>
            <p><strong>Potential IRR:</strong> 4-6%</p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default DisplayBonds;
