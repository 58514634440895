import React from 'react';
import '../assets/styles.css';

function HeroSection() {
  return (
    <section className="hero">
      <img src="London.jpg" alt="Background" className="background-image"/>
      <div className="container">
        <h1>Revolutionize Your Investments with TokAssets</h1>
        <p>Unlock the power of blockchain by tokenizing real-world assets. Invest, trade, and grow your wealth with enhanced security, transparency, and liquidity.</p>
      </div>
    </section>
  );
}

export default HeroSection;