import React from 'react';
import Team from '../components/Team';

function AboutUs() {
  return (
    <div>
      <Team />
    </div>
  );
}

export default AboutUs;
