import React from 'react';
import '../assets/styles.css';
import DisplayPrivateEquity from '../components/ComponentsAssetsDetails/DisplayPrivateEquity';

function PrivateEquity() {
  return (
    <div>
      <DisplayPrivateEquity />
    </div>
  );
}

export default PrivateEquity;
