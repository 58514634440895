import React from 'react';
import '../assets/styles.css';

function Benefits() {
  return (
    <section className="benefits">
      <div className="container">
        <h2>Why Choose TokAssets?</h2>
        <div className="benefit-item">
          <h3>Democratization of Investment with our World-Class Plateform</h3>
          <p>Lower entry barriers and fractional ownership enable everyone to participate in high-value investments that were previously unavailable, and with no management or storage hassle.</p>
        </div>
        <div className="benefit-item">
          <h3>Tokenization services</h3>
          <p>Enabling the digital representation of real-world assets on the blockchain.</p>
        </div>
        <div className="benefit-item">
          <h3>Increased Liquidity</h3>
          <p>Trade tokenized assets 24h/24h with ease and flexibility, boosting liquidity and access to global markets.</p>
        </div>
        <div className="benefit-item">
          <h3>Automated Compliance</h3>
          <p>Streamline compliance and register management through automated blockchain processes.</p>
        </div>
        <div className="benefit-item">
          <h3>Transparency and Security</h3>
          <p>Enjoy full transparency and immutable security with blockchain technology.</p>
        </div>
      </div>
    </section>
  );
}

export default Benefits;
