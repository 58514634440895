import React from 'react';
import { useNavigate } from 'react-router-dom'; // for navigation
import '../../assets/styles.css';

function DisplayCollectibles() {
  const navigate = useNavigate();

  // Function to go back to the previous page
  const goBack = () => {
    navigate(-1); // Go back to the page where the user came from
  };

  return (
    <section className="investment-details">
      <div className="container">
        
        {/* Back Link Row */}
        <div className="back-link">
          <button onClick={goBack}>&larr; Back</button>
        </div>

        <div className="investment-content">
          {/* Left-Aligned Image Section */}
          <div className="investment-image">
            <img src="/Car.jpg" alt="Collectibles Illustration" />
          </div>

          {/* Investment Details */}
          <div className="investment-text">
            <h2>Rare Collectibles (Vintage Cars, Watches)</h2>
            <p>
              Rare collectibles, such as vintage cars and luxury watches, are physical items that have cultural, historical, or artistic value. These assets are often sought after by collectors and investors due to their scarcity and potential for appreciation over time.
            </p>
            <h3>How It Works</h3>
            <p>
              With tokenization, investors can purchase fractional ownership of high-value collectibles. Instead of buying the entire asset, you can invest in a portion of it through digital tokens that represent your share. These tokens can be traded on our platform, offering liquidity in a traditionally illiquid market.
            </p>
            <p>
              For example, a rare vintage car may be worth millions, but through tokenization, you can own a fraction of the car for a much smaller investment. The value of your tokens may appreciate as the asset becomes rarer or more desirable.
            </p>
            <p><strong>Potential IRR:</strong> 8%</p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default DisplayCollectibles;
