import React from 'react';
import '../assets/styles.css';
import DisplayDiamonds from '../components/ComponentsAssetsDetails/DisplayDiamonds';

function Diamonds() {
  return (
    <div>
      <DisplayDiamonds />
    </div>
  );
}

export default Diamonds;
