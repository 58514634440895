import React from 'react';
import '../assets/styles.css';
import DisplayShares from '../components/ComponentsAssetsDetails/DisplayShares';

function Shares() {
  return (
    <div>
      <DisplayShares />
    </div>
  );
}

export default Shares;
