import React from 'react';
import Features from '../components/Features';

function FeaturesPage() {
  return (
    <div>
      <Features />
    </div>
  );
}

export default FeaturesPage;
