import React from 'react';
import '../assets/styles.css';
import { Link } from 'react-router-dom'; // Import Link for navigation

function Investments() {
  return (
    <section className="assets">
      <div className="container">
        <h2>Tokenized Real-World Assets</h2>
        <p className="assets-subtitle">Any real-world asset can be tokenized, and the list here is not exhaustive.</p>
        <div className="asset-list">
          <div className="asset-item">
            <Link to="../pages/Collectibles" className="asset-link">
              <h3>Rare Collectibles (Vintage Cars, Watches)</h3>
              <p>Invest in fractions of rare vintage cars and luxury watches.</p>
              <div className="irr">Min Potential IRR: 8%</div>
            </Link>
          </div>
          <div className="asset-item">
            <Link to="../pages/shares" className="asset-link">
              <h3>Shares</h3>
              <p>Own shares of tokenized companies with easy trading and transparency.</p>
              <div className="irr">Min Potential IRR: 8-12%</div>
            </Link>
          </div>
          <div className="asset-item">
            <Link to="../pages/private-equity" className="asset-link">
              <h3>Private Equity Funds (LBO, Venture, Growth)</h3>
              <p>Access exclusive private equity investments in LBOs, venture, and growth funds.</p>
              <div className="irr">Min Potential IRR: 10-15%</div>
            </Link>
          </div>
          <div className="asset-item">
            <Link to="../pages/diamonds" className="asset-link">
              <h3>Diamonds</h3>
              <p>Diversify with tokenized diamonds.</p>
              <div className="irr">Min Potential IRR: 12%</div>
            </Link>
          </div>
          <div className="asset-item">
            <Link to="../pages/fine-art" className="asset-link">
              <h3>Fine Art</h3>
              <p>Invest in fractional ownership of rare artworks.</p>
              <div className="irr">Min Potential IRR: 7%</div>
            </Link>
          </div>
          <div className="asset-item">
            <Link to="../pages/bonds" className="asset-link">
              <h3>Bonds</h3>
              <p>Purchase tokenized bonds and earn stable returns with less risk.</p>
              <div className="irr">Min Potential IRR: 4-6%</div>
            </Link>
          </div>
          <div className="asset-item">
            <Link to="../pages/gold" className="asset-link">
              <h3>Gold</h3>
              <p>Own fractions of tokenized gold bars.</p>
              <div className="irr">Min Potential IRR: 5%</div>
            </Link>
          </div>
          <div className="asset-item">
            <Link to="../pages/real-estate" className="asset-link">
              <h3>Real Estate</h3>
              <p>Invest in tokenized properties across the globe.</p>
              <div className="irr">Min Potential IRR: 8%</div>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Investments;
