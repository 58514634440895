import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../assets/styles.css'; // Import your styles

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="navbar">
      <div className="container">
        <Link to="/" className="logo">
          <div className="logo-circle">
            <span>TKA</span>
          </div>
          <span className="logo-text">TokAssets</span>
        </Link>

        {/* Burger Icon */}
        <div className={`burger ${isOpen ? 'open' : ''}`} onClick={toggleMenu}>
          <div className="line"></div>
          <div className="line"></div>
          <div className="line"></div>
        </div>

        {/* Navbar Links */}
        <ul className={`nav-links ${isOpen ? 'show' : ''}`}>
          <li><Link to="/" onClick={toggleMenu}>Home</Link></li>
          <li><Link to="/features" onClick={toggleMenu}>Features</Link></li>
          <li><Link to="/about" onClick={toggleMenu}>About Us</Link></li>
          <li><Link to="/whitepaper" onClick={toggleMenu}>White Paper</Link></li> {/* Added White Paper Link */}
          <li><Link to="/presale" onClick={toggleMenu}>Presale</Link></li> 
          <li><Link to="/contact" onClick={toggleMenu}>Contact</Link></li>
        </ul>
      </div>
    </nav>
  );
}

export default Navbar;
