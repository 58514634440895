import React from 'react';
import '../assets/styles.css';
import DisplayPresale from '../components/DisplayPresale';

function Presale() {
  return (
    <div>
      <DisplayPresale />
    </div>
  );
}

export default Presale;