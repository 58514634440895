import React from 'react';
import '../assets/styles.css';
import DisplayBonds from '../components/ComponentsAssetsDetails/DisplayBonds';

function Bonds() {
  return (
    <div>
      <DisplayBonds />
    </div>
  );
}

export default Bonds;

