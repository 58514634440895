import React from 'react';
import { useNavigate } from 'react-router-dom'; // for navigation
import '../../assets/styles.css';

function DisplayPrivateEquity() {
  const navigate = useNavigate();

  // Function to go back to the previous page
  const goBack = () => {
    navigate(-1); // Go back to the page where the user came from
  };

  return (
    <section className="investment-details">
      <div className="container">
        
        {/* Back Link Row */}
        <div className="back-link">
          <button onClick={goBack}>&larr; Back</button>
        </div>

        <div className="investment-content">
          {/* Left-Aligned Image Section */}
          <div className="investment-image">
            <img src="/PrivateEquity.jpg" alt="Private Equity Illustration" />
          </div>

          {/* Investment Details */}
          <div className="investment-text">
            <h2>Private Equity Funds (LBO, Venture, Growth)</h2>
            <p>
              Private equity involves investing in privately-held companies, often through leveraged buyouts (LBO), venture capital for startups, or growth equity for established companies looking to expand. Traditionally, private equity investments have been accessible only to high-net-worth individuals and institutional investors due to large capital requirements.
            </p>
            <h3>How It Works</h3>
            <p>
              Tokenization makes private equity more accessible by allowing investors to purchase fractional ownership in private equity funds. Through tokenized private equity, investors can diversify their portfolios with investments in private companies without needing the significant capital traditionally required.
            </p>
            <p>
              These tokens represent ownership in private equity funds, and as the underlying companies grow or get acquired, investors can benefit from capital appreciation and dividends.
            </p>
            <p><strong>Potential IRR:</strong> 10-15%</p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default DisplayPrivateEquity;
